import api from "@/api"

export const loginUser = async ({commit}, user) => {
  try {
    const {data} = await api.post("login", user)
    if (data.ok) {
      commit("loginUser", data)
      return {ok: true, message: "UserLogin"}
    } else {
      return {ok: false, message: data.message}
    }
  } catch (error) {
    return {ok: false, message: error}
  }
}

export const authCheck = async ({commit}, ruta) => {
  try {
    const token = localStorage.getItem("medicos-api")
    if (!token) {
      commit("logoutUser")
      return {ok: false, message: "No hay token"}
    }

    const {data} = await api.post("check")

    if (data.ok) {
      commit("setNombre", data.nombre)
      commit("setEmail", data.email)
      commit("setFoto", data.foto)
      return {ok: true, message: "Logged"}
    } else {
      commit("logoutUser")
      return {...data}
    }
  } catch (error) {
    commit("logoutUser")
    return {ok: false, message: error}
  }
}

export const logoutUser = async ({commit}) => {
  commit("logoutUser")
}
