export const pacientes = (state) => {
  return state.pacientes;
};

export const loading = (state) => {
  return state.loading;
};

export const catalogos = (state) => {
  return state.catalogos;
};

export const guardando = (state) => {
  return state.guardando;
};

export const consultas = (state) => {
  return state.consultas;
};
