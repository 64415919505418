<template>
  <div
    class="
      sa-app
      sa-app--mobile-sidebar-hidden
      sa-app--toolbar-fixed
      sa-app--desktop-sidebar-shown
    "
  >
    <Sidebar />
    <Main />
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Main from "./Main.vue";
export default {
  components: { Sidebar, Main },
  setup() {
    return {};
  },
};
</script>