export const nombre = (state) => {
  return state.nombre
}

export const email = (state) => {
  return state.email
}

export const foto = (state) => {
  return state.foto
}

export const token = (state) => {
  return state.token
}
