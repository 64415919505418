import api from "../../../api"

export const getUsuarios = async ({commit}, filtro) => {
  const {data} = await api.post("usuarios", {filtro})
  if (data.ok) {
    commit("setUsuarios", data.usuarios)
  }

  return data
}

export const setUsuarios = async ({commit}, usuario) => {
  const {data} = await api.post("usuarios-guardar", usuario)
  return data
}

export const deleteUsuarios = async ({commit}, cid) => {
  const {data} = await api.post("usuarios-eliminar", {cid})
  return data
}
