const {computed} = require("vue")
const {useStore} = require("vuex")

const useAuth = () => {
  const store = useStore()

  const loginUser = async (user) => {
    return await store.dispatch("auth/loginUser", user)
  }

  const logoutUser = async () => {
    await store.dispatch("auth/logoutUser")
  }

  return {
    nombre: computed(() => store.getters["auth/nombre"]),
    email: computed(() => store.getters["auth/email"]),
    foto: computed(() => store.getters["auth/foto"]),
    loginUser,
    logoutUser,
  }
}

export default useAuth
