export default {
  component: () => import("../../../template/Index"),
  children: [
    {
      path: "",
      name: "usuarios",
      component: () => import("../components/Usuarios"),
    },
  ],
}
