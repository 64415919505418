import api from "../../../api";

export const getPacientes = async ({ commit }, filtro) => {
  commit("setLoading", true);
  const { data } = await api.post("pacientes", { filtro });
  commit("setLoading", false);
  if (data.ok) {
    commit("setPacientes", data.pacientes);
  }

  return { ok: data.ok, message: data.message };
};

export const getCatalogos = async ({ commit }) => {
  const { data } = await api.post("catalogos");
  if (data.ok) {
    commit("setCatalogos", data.catalogos);
  }
};

export const setPacientes = async ({ commit }, datos) => {
  commit("setGuardando", true);
  const { data } = await api.post("guardar", datos);
  commit("setGuardando", false);
  return data;
};

export const setEliminar = async ({ commit }, id) => {
  const { data } = await api.post("eliminar", { id });
  return data;
};

export const getConsultas = async ({ commit }, id) => {
  const { data } = await api.post("consultas", { id });
  if (data.ok) {
    commit("setConsultas", data.consultas);
  } else {
    commit("setConsultas", []);
  }

  return data;
};

export const setConsulta = async ({ commit }, datos) => {
  const { data } = await api.post("consultas-guardar", datos);
  return data;
};

export const setConsultaEliminar = async ({ commit }, id) => {
  const { data } = await api.post("consultas-eliminar", { id });
  return data;
};
