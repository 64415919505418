import {createRouter, createWebHistory} from "vue-router"
import pacientes from "../modules/pacientes/router"
import usuarios from "../modules/usuarios/router"
import auth from "../modules/auth/router"
import isAuth from "../modules/auth/router/auth-guard"

const routes = [
  {
    path: "/",
    redirect: "/pacientes",
  },
  {
    path: "/pacientes",
    beforeEnter: [isAuth],
    ...pacientes,
  },
  {
    path: "/usuarios",
    beforeEnter: [isAuth],
    ...usuarios,
  },
  {
    path: "/ingresar",
    ...auth,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/ingresar",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
