export const setPacientes = (state, pacientes) => {
  state.pacientes = pacientes;
};

export const setLoading = (state, loading) => {
  state.loading = loading;
};

export const setCatalogos = (state, catalogos) => {
  state.catalogos = catalogos;
};

export const setGuardando = (state, guardando) => {
  state.guardando = guardando;
};

export const setConsultas = (state, consultas) => {
  state.consultas = consultas;
};
