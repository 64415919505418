export default {
  pacientes: [],
  loading: false,
  guardando: false,
  catalogos: {
    civiles: [],
    estados: [],
    municipios: [],
    medios: [],
  },
  consultas: [],
};
