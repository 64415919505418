export default {
  component: () => import("../../../template/Index"),
  children: [
    {
      path: "",
      name: "pacientes",
      component: () => import("../components/Pacientes"),
    },
    {
      path: "nuevo",
      name: "pacientes-nuevo",
      component: () => import("../components/PacientesForma"),
    },
    {
      path: "detalle/:cid",
      name: "pacientes-detalle",
      props: (route) => {
        const cid = route.params.cid;
        return { cid };
      },
      component: () => import("../components/PacientesDetalle"),
    },
    {
      path: "editar/:cid",
      name: "pacientes-editar",
      props: (route) => {
        const cid = route.params.cid;
        return { cid };
      },
      component: () => import("../components/PacientesForma"),
    },
  ],
};
