<template>
  <div class="sa-app__sidebar">
    <div class="sa-sidebar">
      <div class="sa-sidebar__header">
        <img
          src="/images/invertido-horizontal-alta.png"
          class="max-h-100 p-2 max-w-100"
        />
      </div>
      <div class="sa-sidebar__body" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px">
              <div
                class="simplebar-content-wrapper"
                style="height: 100%; overflow: hidden scroll"
              >
                <div class="simplebar-content" style="padding: 0px">
                  <ul class="sa-nav sa-nav--sidebar" data-sa-collapse="">
                    <li class="sa-nav__section">
                      <div class="sa-nav__section-title">
                        <span>Menu</span>
                      </div>
                      <ul class="sa-nav__menu sa-nav__menu--root">
                        <li
                          class="sa-nav__menu-item sa-nav__menu-item--has-icon"
                        >
                          <router-link
                            to="/"
                            class="sa-nav__link"
                            active-class="active"
                            ><span class="sa-nav__icon"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <path
                                  d="M8,13.1c-4.4,0-8,3.4-8-3C0,5.6,3.6,2,8,2s8,3.6,8,8.1C16,16.5,12.4,13.1,8,13.1zM8,4c-3.3,0-6,2.7-6,6c0,4,2.4,0.9,5,0.2C7,9.9,7.1,9.5,7.4,9.2l3-2.3c0.4-0.3,1-0.2,1.3,0.3c0.3,0.5,0.2,1.1-0.2,1.4l-2.2,1.7c2.5,0.9,4.8,3.6,4.8-0.2C14,6.7,11.3,4,8,4z"
                                ></path></svg></span
                            ><span class="sa-nav__title"
                              >Dashboard</span
                            ></router-link
                          >
                        </li>
                        <li
                          class="sa-nav__menu-item sa-nav__menu-item--has-icon"
                        >
                          <router-link
                            to="/pacientes"
                            class="sa-nav__link"
                            active-class="active"
                            ><span class="sa-nav__icon"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <path
                                  d="M8,10c-3.3,0-6,2.7-6,6H0c0-3.2,1.9-6,4.7-7.3C3.7,7.8,3,6.5,3,5c0-2.8,2.2-5,5-5s5,2.2,5,5c0,1.5-0.7,2.8-1.7,3.7c2.8,1.3,4.7,4,4.7,7.3h-2C14,12.7,11.3,10,8,10z M8,2C6.3,2,5,3.3,5,5s1.3,3,3,3s3-1.3,3-3S9.7,2,8,2z"
                                ></path></svg></span
                            ><span class="sa-nav__title"
                              >Pacientes</span
                            ></router-link
                          >
                        </li>
                        <li
                          class="sa-nav__menu-item sa-nav__menu-item--has-icon"
                        >
                          <router-link
                            to="/usuarios"
                            class="sa-nav__link"
                            active-class="active"
                          >
                            <span class="sa-nav__icon"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <path
                                  d="M8,6C4.7,6,2,4.7,2,3s2.7-3,6-3s6,1.3,6,3S11.3,6,8,6z M2,5L2,5L2,5C2,5,2,5,2,5z M8,8c3.3,0,6-1.3,6-3v3c0,1.7-2.7,3-6,3S2,9.7,2,8V5C2,6.7,4.7,8,8,8z M14,5L14,5C14,5,14,5,14,5L14,5z M2,10L2,10L2,10C2,10,2,10,2,10z M8,13c3.3,0,6-1.3,6-3v3c0,1.7-2.7,3-6,3s-6-1.3-6-3v-3C2,11.7,4.7,13,8,13z M14,10L14,10C14,10,14,10,14,10L14,10z"
                                ></path></svg
                            ></span>
                            <span class="sa-nav__title"
                              >Usuarios</span
                            ></router-link
                          >
                        </li>
                        <li
                          class="sa-nav__menu-item sa-nav__menu-item--has-icon"
                        >
                          <a
                            @click="handleLogout"
                            class="sa-nav__link cursor-pointer"
                            active-class="active"
                          >
                            <span class="sa-nav__icon"
                              ><svg
                                class="svg-inline--fa fa-lock fa-w-14"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="lock"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                                ></path></svg
                            ></span>
                            <span class="sa-nav__title">Salir</span></a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="simplebar-placeholder"
            style="width: auto; height: 935px"
          ></div>
        </div>
        <div
          class="simplebar-track simplebar-horizontal"
          style="visibility: hidden"
        >
          <div
            class="simplebar-scrollbar"
            style="width: 0px; display: none"
          ></div>
        </div>
        <div
          class="simplebar-track simplebar-vertical"
          style="visibility: visible"
        >
          <div
            class="simplebar-scrollbar"
            style="
              height: 736px;
              transform: translate3d(0px, 0px, 0px);
              display: block;
            "
          ></div>
        </div>
      </div>
    </div>
    <div class="sa-app__sidebar-shadow"></div>
    <div class="sa-app__sidebar-backdrop" data-sa-close-sidebar=""></div>
  </div>
  <div class="sa-app__sidebar-shadow"></div>
  <div class="sa-app__sidebar-backdrop" data-sa-close-sidebar=""></div>
</template>

<script>
import jQuery from "jquery";
import useAuth from "../modules/auth/composables/useAuth";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const { logoutUser } = useAuth();
    const handleLogout = async () => {
      await logoutUser();
      router.push("/ingresar");
    };
    return { handleLogout };
  },
  mounted() {
    (function ($, window) {
      window.stroyka = {};

      /*
    // Sidebar
    */
      window.stroyka.sidebar = (function () {
        const appEl = $(".sa-app");

        $("[data-sa-toggle-sidebar]").on("click", function () {
          window.stroyka.sidebar.toggle();
        });
        $("[data-sa-open-sidebar]").on("click", function () {
          window.stroyka.sidebar.open();
        });
        $("[data-sa-close-sidebar]").on("click", function () {
          window.stroyka.sidebar.close();
        });

        const media = matchMedia("(min-width: 1200px)");

        function onMediaChange() {
          window.stroyka.sidebar.toggle(media.matches);

          appEl.addClass("sa-app--switch-device");
          appEl.width(); // force reflow
          appEl.removeClass("sa-app--switch-device");
        }

        if (media.addEventListener) {
          media.addEventListener("change", onMediaChange);
        } else {
          media.addListener(onMediaChange);
        }

        return {
          toggle: function (value) {
            const shownClass =
              "sa-app--" +
              (media.matches ? "desktop" : "mobile") +
              "-sidebar-shown";
            const hiddenClass =
              "sa-app--" +
              (media.matches ? "desktop" : "mobile") +
              "-sidebar-hidden";
            const curState =
              appEl.hasClass(shownClass) && !appEl.hasClass(hiddenClass);
            const newState = typeof value === "boolean" ? value : !curState;

            if (newState) {
              appEl.addClass(shownClass);
              appEl.removeClass(hiddenClass);
            } else {
              appEl.removeClass(shownClass);
              appEl.addClass(hiddenClass);
            }
          },
          open: function () {
            window.stroyka.sidebar.toggle(true);
          },
          close: function () {
            window.stroyka.sidebar.toggle(false);
          },
        };
      })();

      /*
    // Sidebar menu state
    */
      window.stroyka.sidebarMenuState = (function () {
        const storageKey = "sa-sidebar-menu-state";
        const $sidebarBody = $(".sa-sidebar__body");

        if ($sidebarBody.length > 0) {
          let itemsState = sessionStorage.getItem(storageKey);

          if (itemsState) {
            itemsState = JSON.parse(itemsState);

            itemsState.forEach(function (path) {
              const itemQuery = path.slice(1).map(function (itemIndex) {
                return (
                  ".sa-nav__menu > .sa-nav__menu-item:eq(" + itemIndex + ")"
                );
              });
              const query =
                ".sa-nav__section:eq(" +
                path[0] +
                ") > " +
                itemQuery.join(" > ");

              $(query).addClass("sa-nav__menu-item--open");
            });
          }

          $sidebarBody.on("sa-collapse-state", function () {
            function getMenuState(parentElement, path) {
              path = path || [];

              let result = [];

              $("> .sa-nav__menu > .sa-nav__menu-item", parentElement).each(
                function (index, element) {
                  const currentPath = path.concat([index]);

                  if ($(element).is(".sa-nav__menu-item--open")) {
                    result.push(currentPath);
                  }

                  result = result.concat(getMenuState(element, currentPath));
                }
              );

              return result;
            }

            let currentState = [];

            $(".sa-nav__section").each(function (sectionIndex, sectionElement) {
              currentState = currentState.concat(
                getMenuState(sectionElement, [sectionIndex])
              );
            });

            sessionStorage.setItem(storageKey, JSON.stringify(currentState));
          });
        }

        return {
          clear: function () {
            sessionStorage.removeItem(storageKey);
          },
        };
      })();

      /*
    // Sidebar scrollbar state
    */
      window.stroyka.sidebarScrollbarState = (function () {
        const storageKey = "sa-sidebar-scrollbar-state";
        const $sidebarBody = $(".sa-sidebar__body");

        if ($sidebarBody.length > 0) {
          const simpleBar = new SimpleBar($sidebarBody[0]);

          simpleBar.getScrollElement().addEventListener("scroll", function () {
            sessionStorage.setItem(
              storageKey,
              JSON.stringify({
                value: simpleBar.getScrollElement().scrollTop,
                windowHeight: window.outerHeight,
              })
            );
          });

          let scrollState = sessionStorage.getItem(storageKey);

          if (scrollState) {
            scrollState = JSON.parse(scrollState);

            if (scrollState.windowHeight === window.outerHeight) {
              simpleBar.getScrollElement().scrollTo(0, scrollState.value);
            }
          }
        }

        return {
          clear: function () {
            sessionStorage.removeItem(storageKey);
          },
        };
      })();

      /*
    // Layout sidebar
    */
      window.stroyka.layoutSidebar = (function () {
        const $layout = $(".sa-layout");
        const openClass = "sa-layout--sidebar-open";
        const media = matchMedia("(min-width: 1600px)");

        function onMediaChange() {
          if (!media.matches) {
            window.stroyka.layoutSidebar.close();
          }

          $layout.addClass("sa-layout--switch-device");
          $layout.width(); // force reflow
          $layout.removeClass("sa-layout--switch-device");
        }

        if (media.addEventListener) {
          media.addEventListener("change", onMediaChange);
        } else {
          media.addListener(onMediaChange);
        }

        $("[data-sa-layout-sidebar-open]").on("click", function () {
          window.stroyka.layoutSidebar.open();
        });
        $("[data-sa-layout-sidebar-close]").on("click", function () {
          window.stroyka.layoutSidebar.close();
        });

        return {
          toggle: function (value) {
            const curState =
              $layout.hasClass(openClass) && !$layout.hasClass(openClass);
            const newState = typeof value === "boolean" ? value : !curState;

            $layout.toggleClass(openClass, newState);
          },
          open: function () {
            window.stroyka.layoutSidebar.toggle(true);
          },
          close: function () {
            window.stroyka.layoutSidebar.toggle(false);
          },
        };
      })();
    })(jQuery, window);
  },
};
</script>

<style lang="scss" scoped>
</style>