<template>
  <router-view />
</template>

<script>
import Index from "./template/Index.vue";
export default {
  components: { Index },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>