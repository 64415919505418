import {createStore} from "vuex"
import pacientes from "../modules/pacientes/store"
import auth from "../modules/auth/store"
import usuarios from "../modules/usuarios/store"

const store = createStore({
  modules: {
    pacientes,
    auth,
    usuarios,
  },
})

export default store
