export const loginUser = (state, {nombre, token, email, foto}) => {
  if (token) {
    localStorage.setItem("medicos-api", token)
    state.token = token
    state.nombre = nombre
    state.email = email
    state.foto = foto
  }
}

export const logoutUser = (state) => {
  state.token = null
  state.nombre = null
  state.email = null
  state.foto = null
  localStorage.removeItem("medicos-api")
}

export const setNombre = (state, nombre) => {
  state.nombre = nombre
}

export const setEmail = (state, email) => {
  state.email = email
}
export const setFoto = (state, foto) => {
  state.foto = foto
}
