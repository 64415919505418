<template>
  <div class="sa-app__content">
    <Header />
    <div class="sa-app__body">
      <div class="mx-sm-2 px-2 px-sm-3 px-xxl-4 pb-6">
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
export default {
  components: { Header },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>